* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

@font-face {
  font-family: "Causten-Bold";
  src: local("Causten-Bold"), url(./fonts/Causten-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Causten-Regular";
  src: local("Causten-Regular"),
    url(./fonts/Causten-Regular.otf) format("opentype");
}

@font-face {
  font-family: "MyriadPro-Regular";
  src: local("MyriadPro-Regular"),
    url(./fonts/MyriadPro-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Nexa Bold";
  src: local("Nexa Bold"), url(./fonts/Nexa\ Bold.otf) format("opentype");
}
@font-face {
  font-family: "Nexa Light";
  src: local("Nexa Light"), url(./fonts/Nexa\ Light.otf) format("opentype");
}

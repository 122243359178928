.footer {
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  position: relative;
  bottom: 0 !important;
  z-index: 22;
  background-color: #f5f4f9;
  display: flex;
  justify-content: space-around;
  overflow-x: hidden;
  padding: 45px 20px;
  margin-top: 230px !important;
}

.copyright {
  color: #121f55;
  font-size: 30px;
  font-family: "Nexa Light";
}
.links {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.links img {
  width: 30px;
}
.links img:nth-child(4) {
  width: 20px;
}

@media only screen and (max-width: 1000px) {
  .footer {
    padding: 20px !important;
    margin-top: 140px !important;
  }
  .footer-logo {
    margin: 6px 0;
  }
  .footer-logo h3 {
    font-size: 22px;
  }
  .footer-logo img {
    width: 40px;
  }
  .links {
    width: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .links img {
    width: 16px;
  }
  .links img:nth-child(4) {
    width: 10px;
  }
  .copyright {
    margin: 9px 0;
    font-size: 14px;
  }
}

.progressLow {
  width: 80px;
  height: 80px;
  font-family: "Nexa Light";
  position: relative;
}
.progressLow .progress-icon {
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fa-thumbs-up {
  color: #17bf91;
}
.fa-thumbs-down {
  color: #d22424;
}
@media only screen and (max-width: 1000px) {
  .progressLow {
    width: 45px;
    height: 40px;
  }
  .progressLow .progress-icon {
    font-size: 14px;
  }
}

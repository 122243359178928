.login-container {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #ececec;
  position: relative;
}
.content-image {
  position: absolute;
  right: 0;
  width: 900px;
}

.login-box {
  width: 90%;
  height: 95%;
  display: flex;
  background-color: #fff;
}
.login-box-form {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-box-form p {
  font-size: 20px;
  color: #4e4e4e;
  font-family: "Nexa Light";
  margin-top: 10px;
  overflow: hidden;
}

.login-box-form-logo {
  margin-top: 2rem;
 overflow: hidden;
  cursor: pointer;
}

.login-box-form .linkedin-button {
  padding: 15px;
  width: 400px;
  border: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 25px;
  box-shadow: 0px 3px 19px #0000001a;
  margin-top: 30px;
  color: #4e4e4e;
  font-family: "Nexa Light";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  transition: 0.5s ease;
}
.login-box-form .linkedin-button:hover {
  background: #365381 0% 0% no-repeat padding-box;
  color: #fffdfd;
}

.login-box-form .linkedin-button img {
  margin-right: 10px;
}
.or-line {
  display: flex;
  align-items: center;
}
.or-line .line1,
.or-line .line2 {
  width: 140px;
  margin: 20px;
  height: 1px;
  background-color: #cdcbcb;
}
.or-line p {
  margin-top: 0px;
  font-family: "Nexa Light";
  font-size: 17px;
}
.login-form {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.login-form .email-input,
.login-form .password-input {
  width: 414px;
  height: 68px;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 3px 19px #9090901a;
  border: 1px solid #dedede;
  margin-bottom: 20px;
}
.login-form .email-input input,
.login-form .password-input input {
  border: 0;
  outline: none;
  width: 100%;
  padding: 20px;
  font-family: "Nexa Light";
}
.password-input {
  display: flex;
  align-items: center;
}
.password-input i {
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
}

.login-form button {
  width: 414px;
  height: 50px;
  border: 0;
  background: #121f55 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 19px #0000001a;
  border-radius: 25px;
  color: #fff;
  text-align: center;
  font-family: "Nexa Light";
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form button:hover {
  background: #031e8c 0% 0% no-repeat padding-box;
}

.signup-link p {
  font-family: "Nexa Light";
  font-size: 20px;
}
.signup-link p span {
  font-family: "Nexa Bold";
  color: #121f55;
  cursor: pointer;
}
.gmail-button:hover {
  background-color: rgb(184, 73, 8) !important;
}

.login-box-color {
  width: 50%;
  height: 100%;
  background-color: #121f55;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

@media only screen and (max-width: 1000px) {
  .content-image {
    display: none;
  }

  .login-box {
    width: 92%;
    min-height: 80%;
    border-radius: 10px;
    box-shadow: 0px 3px 19px #0000001a;
  }
  .login-box-form {
    width: 100%;
  }
  .login-box-form p {
    font-size: 15px;
    color: #4e4e4e;
    font-family: "Nexa Light";
    margin-top: 20px;
  }
  .login-box-form .linkedin-button {
    padding: 15px;
    width: 300px;
    border-radius: 22px;
    box-shadow: 0px 3px 19px #0000001a;
    margin-top: 20px;
    font-size: 15px;
  }
  .login-box-form .linkedin-button img {
    margin-right: 10px;
    width: 20px;
  }
  .or-line {
    display: flex;
    align-items: center;
  }
  .or-line .line1,
  .or-line .line2 {
    width: 100px;
    margin: 10px;
    height: 1px;
    background-color: #c6c5c5;
  }
  .or-line p {
    font-size: 14px;
    text-align: center;
  }
  .login-form {
    margin-top: 30px;
  }
  .login-form .email-input,
  .login-form .password-input {
    width: 254px;
    height: 58px;
    margin-bottom: 16px;
  }
  .login-form .email-input input,
  .login-form .password-input input {
    padding: 16px;
  }
  .login-form button {
    width: 254px;
    height: 50px;
    border-radius: 15px;
    margin-top: 10px;
    font-size: 16px;
  }

  .signup-link p {
    font-family: "Nexa Light";
    font-size: 16px;
  }
  .login-box-color {
    display: none;
  }
}

.card {
  max-width: 300px;
  min-height: 350px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgba(17, 12, 46, 0.08) 0px 48px 20px 0px;
  overflow-x: hidden;
  margin-left: 17px;
  margin-top: 15px;
  cursor: pointer;
  padding: 20px;
}

.row1 {
  display: flex;
  align-items: center;
}

.row1 .logo img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}


.row1 .title h1 {
  font-family: "Nexa Bold";
  font-size: 18px;
  margin-left: 6px;
  margin-bottom: 6px;
  text-transform: lowercase;
  color: #272b65;
}

.row1 .title p {
  font-family: "Nexa Light";
  font-size: 10px;
  opacity: 0.4;
  margin-left: 6px;
}

.row3 {
  margin-top: 10px;
}

.row3-row1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
}

.row3-row1 p {
  font-size: 10px;
  font-family: "Nexa Light";
  flex: 1;
}

.row4 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  padding-top: 10px;
}

.row4 p {
  font-size: 8px;
  font-family: "Nexa Light";
  margin-top: 12px;
}

.ratings {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .card {
    max-width: 85%;
    min-height: 200px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    overflow-x: hidden;
    padding: 8px;
    position: relative;
    margin-left: 0 5px;
  }

  .row1 {
    display: flex;
    align-items: center;
    padding: 0px 1px;
  }

  .row1 .logo img {
    width: 20px;
    height: 20px;
  }

  .row1 .title h1 {
    font-family: "MyriadPro-Regular";
    font-size: 10px;
    margin-bottom: 0;
    margin-left: 4px;
    color: #272b65;
    text-transform: lowercase;
  }

  .row1 .title p {
    font-size: 6px;
  }

  .row3 {
    width: 100%;
    padding: 10px 5px;
  }

  .row3-row1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 4px 0;
    width: 100%;
  }

  .row3-row1 p {
    font-size: 8px;
    font-family: "Causten-Regular";
    margin: 2px 0;
  }

  .row4 {
    margin-top: 0px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0px;
    right: 10px;
  }

  .ratings {
    padding-bottom: 0px;

  }

  .row4 p {
    display: none;
  }
}
.progress {
  width: 70px;
  height: 70px;
  font-family: "Nexa Light";
  font-size: 12px;
}

@media only screen and (max-width: 1000px) {
  .progress {
    width: 35px;
    height: 40px;
  }
}
.add-client-button {
    padding: 10px;
    width: auto;
    align-self: flex-end;
    background-color: #fff;
    outline: none;
    border: 0;
    border-radius: 12px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Nexa Light';
    font-size: 15px;
    cursor: pointer;
}

.new-header-logo {
    flex: .9;
}

@media only screen and (max-width: 1000px) {
    .help {
        display: none !important;
    }

    .add-client-button {
        padding: 8px;
        width: 90px;
        font-size: 10px;
        margin-right: 10px;
    }

    .invoice-logo {
        font-size: 20px;
    }
}
.Loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #121f55;
  align-items: center;
  justify-content: center;
}
.dots {
  width: 13.4px;
  height: 13.4px;
  background: #ffffff;
  color: #ffffff;
  border-radius: 50%;
  box-shadow: 13.4px 0, -26.9px 0;
  animation: dots-h5boszmd 0.5s infinite linear alternate;
}

@keyframes dots-h5boszmd {
  50% {
    box-shadow: 13.4px 0, -13.4px 0;
  }

  100% {
    box-shadow: 26.9px 0, -13.4px 0;
  }
}

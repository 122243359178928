.progressHigh {
  width: 160px;
  height: 160px;
  font-family: "Nexa Light";

}

@media only screen and (max-width: 1000px) {
  .progressHigh {
    width: 70px;
    height: 70px;
    margin-top: 30px;
  }
}
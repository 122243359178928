.add-company-form-logo {
  cursor: pointer;
}
.add-client-modal{
  width: 400px !important;
  height:auto;
  padding: 50px !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction:  column !important;
  align-items: center !important;
}
.add-client-modal .rate-form-header .company-name-input input{
  margin-left: 0 !important;
}
.add-client-modal .cross{
  right: 10px !important;
  top: 10px;

}
.company-name-input {
  height: 60px;
  margin-left: 30px;
  width: 300px;
}
.add-client-modal .rate-form-header .company-name-input{
  margin-left: 0 !important;
}
.company-name-input input {
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: transparent;
  font-family: "Nexa Light";
  font-size: 16px;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}
.rate-form-input-text-add-company {
  width: 100%;
  height: 255px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  margin-top: 20px;
}
.rate-form-input-text-add-company textarea {
  border: 0;
  width: 100%;
  height: 80%;
  background: transparent;
  font-family: "Nexa Light";
  font-size: 18px;
  outline: none;
  padding: 20px;
}
.rate-form-header-add-company {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .company-name-input {
    margin-top: 10px;
    margin-left: 0;
  }
  .add-client-modal{
    width: 350px !important;
    height:auto;
    padding: 40px !important;
    border-radius: 10px !important;
    display: flex !important;
    flex-direction:  column !important;
    align-items: center !important;
  }
  .add-client-modal input{
    width: 90%
  };
  .add-client-modal .cross{
    right: 10px !important;
    top: 10px;
  
  }
  .add-company-form-logo {
    margin-bottom: 20px;
  }
  .rate-form-header-add-company {
    flex-direction: column;
  }
  .rate-form-input-text-add-company {
    margin-left: 0px;
    height: 150px;
    width: 300px;
  }
  .company-name-input input {
    font-size: 14px;
  }
  .rate-form-input-text-add-company textarea {
    font-size: 14px;
  }
}

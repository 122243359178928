.new-header {
  background-color: #121f55;
  width: 100%;
  max-height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}

.new-header-logo {
  margin-left: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.new-header-logo img {
  width: 30px;
}

.new-header-logo h3 {
  color: #fff;
  font-family: "Nexa Bold";
  font-size: 28px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.new-header a {
  color: #fff;
  font-family: "Nexa Light";
  font-size: 17px;
  align-items: center;
  display: flex;
  margin-right: 100px;
  cursor: pointer;
}

.new-header a i {
  margin-right: 10px;
  font-size: 12px;
}

.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  margin-left: auto;
  margin-right: 30px;
  place-items: center;
  background-color: #fff;
}

.user-icon i {
  font-size: 26px;
}

.invoice-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.invoice-logo {
  color: #fff;
  font-size: 15px;
  margin-left: 10px;
  margin-top: 15px;
  color: rgb(220, 46, 151);

}

.invoice-logo-container p {
  margin-top: 24px;
  margin-left: 5px;
  font-size: 18px;
  color: #fff;
  font-family: "Nexa Bold";

}

.companyLogo {
  width: 40%;
  height: 40%;
}

@media only screen and (max-width: 1000px) {
  .new-header {
    padding: 20px;
    flex-wrap: wrap;
  }

  .new-header-logo {
    margin-left: 0px;
  }

  .user-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .user-icon i {
    font-size: 20px;
  }

  .new-header-logo img {
    width: 30px;
  }

  .new-header-logo h3 {
    font-size: 16px;
    margin-left: 7px;
    margin-bottom: 8px;
  }

  .new-header a {
    font-family: "Nexa Light";
    font-size: 13px;
    margin-right: 4px;
  }

  .new-header a i {
    margin-right: 10px;
    font-size: 10px;
  }

  .invoice-logo {
    color: #fff;
    font-size: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .invoice-logo-container p {
    margin-top: 15px;
    margin-left: 3px;
    font-size: 10px;

  }
}
.search-result-box {
  width: 80%;
  min-height: 60vh;
  background-color: #f4f6fd;
  display: flex;
  flex-direction: column;
  margin: 50px auto;
}
.dropdown-div {
  height: 50px;
  border: 0;
  width: 200px;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  cursor: pointer;
}
.dropdown-div .dropdown {
  width: 100%;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  border: 0;
  vertical-align: middle;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  font-size: 18px;
  font-family: "Nexa Bold";
}
.dropdown-div::after {
  position: absolute;
  right: 0;
  top: 8px;
  content: "\2304";
  font-size: 30px;
  line-height: 23px;
  padding-right: 12px;
}

.search-result-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eef1ff !important;
  height: 120px;
  width: 100%;
  padding: 30px;
}
.search-result-box-header p {
  font-family: "Nexa Bold";
  font-size: 35px;
}
.search-result-box-header p span {
  font-size: 28px;
  font-family: "Nexa Light";
}
.search-result-box-content {
  padding: 20px 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.search-result-box-content h1 {
  text-align: center;
  font-family: "Nexa Light";
  font-size: 24px;
  padding-bottom: 20px;
}
.Add-company-button {
  margin: 0 auto;
  margin-top: 120px;
}

.Add-company-button button {
  width: 300px;
  color: #fff;
  background-color: rgb(137, 9, 58);
  box-shadow: 0px 3px 19px #0000001a;
  border-radius: 10px;
  font-family: "Nexa Light";
  height: 44px;
  border: 0;
  outline: none;
  font-size: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease;
}
.Add-company-button button:hover {
  background-color: rgb(68, 4, 4);
}
.Add-company-button button i {
  margin-right: 10px;
}

@media only screen and (max-width: 1000px) {
  .search-result-box {
    width: 95%;
    margin: 20px auto;
  }
  .dropdown-div {
    height: 40px;
    border: 0;
    width: 160px;
    border-radius: 16px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    align-self: flex-end;
    margin-left: 10px;
  }
  .Add-company-button button {
    width: 240px;
    font-size: 20px;
  }
  .dropdown-div .dropdown {
    width: 100%;
    text-align: center;
    z-index: 1;
    cursor: pointer;
    border: 0;
    vertical-align: middle;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    font-size: 14px;
    font-family: "Nexa Bold";
  }
  .dropdown-div::after {
    position: absolute;
    right: 0px;
    top: 3px;
    content: "\2304";
    font-size: 25px;
    line-height: 23px;
    padding-right: 12px;
  }

  .search-result-box-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #eef1ff !important;
    height: auto;
    width: 100%;
    padding: 30px;
  }
  .search-result-box-header p {
    font-family: "Nexa Bold";
    font-size: 18px;
  }
  .search-result-box-header p span {
    font-size: 14px;
    font-family: "Nexa Light";
  }
  .search-result-box-content {
    padding: 20px 0px;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    width: 100%;
  }
}

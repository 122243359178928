.screenshot-slider {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px #00000024;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  z-index: 447;
  border-radius: 10px;
}
.ss-box {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;
}
.ss-box img {
  width: 90%;
  margin-top: 30px;
}
.cross-ss {
  font-size: 24px;
  position: absolute;
  right: 20px;
  top: 10px;
  color: rgb(163, 9, 9);
  cursor: pointer;
}
@media only screen and (max-width: 1000px) {
  .screenshot-slider {
    width: 100%;
    height: auto;
    padding: 40px;
    position: fixed;
    top: 50%;
  }

  .ss-box img {
    width: 600px;
    margin-top: 30px;
  }
  .cross-ss {
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 5px;
    color: rgb(163, 9, 9);
    cursor: pointer;
  }
}

.landing-container {
  width: 100%;
  height: 770px;
  background-color: #121f55;
  position: relative;
}

.landing-text {
  margin: 0 200px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-text h1 {
  font-size: 60px;
  font-weight: lighter;
  font-family: "Nexa Light";
  color: #fff;
}

.landing-text h1 span {
  font-family: "Nexa Bold";
}

.landing-input {
  margin-top: 26px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 45%;
  position: relative;
}

.landing-input input::placeholder {
  font-size: 20px;
}

.landing-input i {
  transform: rotate(80deg);
  font-size: 20px;
  opacity: 0.3;
}

.landing-input input {
  border: 0;
  width: 100%;
  font-family: "Nexa Light";
  padding: 0px;
  margin-left: 10px;
  outline: none;
  font-size: 20px;
}

input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.landing-text p {
  width: 50%;
  color: #fcfcfc;
  font-family: "Nexa Light";
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  opacity: .8;
}

.top-companies {
  width: 100%;
  position: absolute;
  background-color: transparent;
  top: 600px;
}

.brand-info {
  margin-top: 100px;
  width: 100%;
  height: auto;
  display: flex;
}

.brnad-info-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  justify-content: center;
  padding: 0 160px;
}

.brnad-info-text p:nth-child(1) {
  font-size: 40px;
  font-family: "Nexa Light";
  color: #121f55;
}

.brnad-info-text p:nth-child(2) {
  font-size: 17px;
  margin-top: 20px;
  font-family: "Nexa Light";
  color: #707070;
}

.brand-info-image {
  display: flex;
  justify-content: flex-end;
  width: 70%;
}

@media only screen and (max-width: 1000px) {
  .landing-container {
    width: 100%;
    height: 700px;
    padding-top: 80px;
    background-color: #121f55;
  }

  .landing-text {
    margin: 0 30px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landing-text .landing-input {
    margin-top: 30px;
    padding: 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-color: #fff;
    align-self: flex-start;
    width: 80%;
  }

  .landing-input i {
    transform: rotate(80deg);
    font-size: 14px;
    opacity: 0.5;
  }

  .landing-input input {
    width: 100%;
    margin-left: 5px;
    padding: 6px;
    font-size: 15px;
  }

  .landing-input input::placeholder {
    font-size: 12px;
  }

  .landing-text p {
    width: 100%;
    color: #e8e8e8;
    font-family: "Nexa Light";
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
  }

  .top-companies {
    width: 100%;
    position: absolute;
    background-color: transparent;
    height: auto;
    top: 580px;
  }

  datalist {
    width: 400px !important;
  }

  .brand-info {
    margin-top: 78px;
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
    padding: 0;
  }

  .brand-info-image img {
    width: 200px;
    height: 260px;
  }

  .brnad-info-text {
    width: 100%;
    display: flex;
    padding: 0;
    flex-direction: column;
    text-align: left;
    align-items: center;
    justify-content: center;
  }

  .brnad-info-text p:nth-child(1) {
    font-size: 29px;
    font-family: "Nexa Light";
    color: #121f55;
    margin-left: 15px;
  }

  .brnad-info-text p:nth-child(2) {
    font-size: 16px;
  }

  .brand-info-image {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
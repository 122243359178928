.company-landing-container {
  height: auto;
  display: flex;
  padding: 120px 80px;
  position: relative;
}

.company-landing-container .company-info {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.company-landing-container .company-info .company-info-row1 {
  display: flex;
  align-items: center;
}

.company-info-row1 .logo-circle {
  background-color: #fff;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.logo-circle img {
  width: 60%;
  overflow: hidden;
}

.company-info-row1 .info-text {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  color: #fff;
  align-self: center;
}

.company-info-row1 .info-text a {
  font-family: "Nexa Light";
  font-size: 12px;
  letter-spacing: 2px;
  opacity: 0.8;
  margin: 7px 0;
  text-decoration: none;
  color: rgb(114, 200, 254);
  cursor: pointer;
}

.info-text p:nth-child(1) {
  font-family: "Nexa Bold";
  font-size: 30px;
}

.info-text p:nth-child(3) {
  font-family: "Nexa Light";
  font-size: 12px;
  margin-top: 5px;
}

.company-info .company-info-row2 {
  margin-top: 20px;
  color: #fff;
  width: 90%;
  margin-left: 10px;
}

.company-info-row2 p {
  font-family: "Nexa Light";
  font-size: 146x;
  opacity: 0.6;
}

.company-score {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.company-score .company-score-row1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}

.company-score-row1 p {
  text-transform: uppercase;
  letter-spacing: 4px;
  word-wrap: break-word;
  font-size: 22px;
  font-family: "Nexa Bold";
}

.company-score-row2 {
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.company-score-row2 button {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 19px #0000001a;
  border-radius: 14px;
  font-family: "Nexa Light";
  height: 44px;
  border: 0;
  outline: none;
  font-size: 25px;
  cursor: pointer;
  transition: 0.4s ease;
  margin-top: 12px;
}

.company-score-row2 button:hover {
  background-color: rgb(165, 40, 117);
  color: #fff;
}

.top-ratings {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 20px;
  position: absolute;
  top: 500px;
}

.top-ratings .rating-box {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 39px;
  width: 346px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 25px;
}

.rating-box p {
  font-family: "Nexa Light";
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
  .company-landing-container {
    height: 500px;
    display: flex;
    padding: 10px 20px;
    position: relative;
    flex-direction: column;
  }

  .company-info-row1 .info-text a {
    font-size: 12px;
    letter-spacing: 0px;
    margin: 3px 0;
  }

  .company-landing-container .company-info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .company-landing-container .company-info .company-info-row1 {
    display: flex;
    align-items: center;
  }

  .company-info-row1 .logo-circle {
    width: 70px;
    height: 70px;
  }

  .logo-circle img {
    width: 50%;
  }

  .company-info-row1 .info-text {
    margin-left: 10px;
  }

  .info-text a {
    font-size: 10px;
  }

  .info-text p:nth-child(1) {
    font-family: "Nexa Bold";
    font-size: 20px;
  }

  .info-text p:nth-child(3) {
    font-family: "Nexa Light";
    font-size: 12px;
    margin-top: 6px;
  }

  .company-info .company-info-row2 {
    margin-top: 10px;
    color: #fff;
    width: 100%;
    margin-left: 0px;
  }

  .company-info-row2 p {
    font-family: "Nexa Light";
    font-size: 10px;
    letter-spacing: 1px;
    opacity: 0.9;
  }

  .company-score {
    width: 100%;
  }

  .company-score .company-score-row1 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    margin-top: 22px;
    align-self: center;
  }

  .company-score-row1 p {
    margin-left: 0px;
    width: auto;
    font-size: 22px;
    font-family: "Nexa Bold";
    margin-bottom: 10px;
  }

  .company-score-row2 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .company-score-row2 button {
    width: 60%;
    border-radius: 20px;
    height: 38px;
    font-size: 20px;
    margin-top: 10px;
  }

  .top-ratings {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 0px 0px;
    position: absolute;
    top: 500px;
  }

  .top-ratings .rating-box {
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 25px;
    width: 176px;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 2px;
  }

  .rating-box p {
    font-family: "Nexa Light";
    font-size: 8px;
    margin-bottom: 8px;
  }
}
.short-card {
  width: 385px;
  height: auto;
  margin: 20px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000012;
  border-radius: 12px;
  padding: 20px;
  position: relative;
}
.short-card-row1 .user-info {
  display: flex !important;
  align-items: center;
}
.short-card-row2 {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}
.short-card-row2 p:nth-child(1) {
  font-family: "Nexa Light";
  font-size: 18px;
}
.short-card-row2 p:nth-child(2) {
  font-family: "Nexa Bold";
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  bottom: 164px;
}
.short-card-row3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.short-card-row3 .helpful-buttons {
  width: 70%;
}
.short-card-row3 .helpful-buttons button {
  margin-top: 15px !important;
}
.short-card-row3 .recommended-screenshots img {
  width: 110px;
}
.short-card-row4 {
  flex-direction: column;
  margin-top: 30px;
  display: flex;
  width: 80%;
}
.short-card-row4 .rating-heading {
  font-family: "Nexa Bold";
  font-size: 22px;
  margin-bottom: 10px;
}
.short-card-row4 .individual-ratings .row3-row1 p {
  margin-bottom: 14px;
}
@media only screen and (max-width: 1000px) {
  .short-card {
    width: 360px;
    margin: 5px;
    padding: 15px;
  }
  .short-card-row2 {
    display: flex;
    margin-top: 15px;
  }
  .short-card-row2 p:nth-child(1) {
    font-family: "Nexa Light";
    font-size: 14px;
  }
  .short-card-row2 p:nth-child(2) {
    font-size: 14px;
    right: 40px;
    bottom: 105px;
  }
  .short-card-row3 {
    margin-top: 20px;
  }
  .short-card-row3 .helpful-buttons {
    width: 50%;
  }
  .short-card-row3 .helpful-buttons button {
    margin-top: 15px !important;
  }
  .short-card-row3 .recommended-screenshots img {
    width: 50px;
  }
  .short-card-row4 {
    flex-direction: column;
    margin-top: 20px;
    display: flex;
    width: 60%;
  }

  .short-card-row4 .rating-heading {
    font-family: "Nexa Bold";
    font-size: 18px;
    margin-bottom: 0px;
  }
  .short-card-row4 .individual-ratings .row3-row1 p {
    margin-bottom: 4px;
  }
}
